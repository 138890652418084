@import 'variables'; @import 'variables';
.CommunicationPane {
  height: 100%;

  &-disconnected {
    align-items: center;
    display: flex;
    font-size: 1.2rem;
    height: 100%;
    justify-content: center;
    text-align: center;
  }

  &-disconnectedIcon {
    font-size: 3rem;
  }
}
