@import 'variables'; @import 'variables';
.Chat {
  height: 100%;
  position: relative;

  &-messageList {
    bottom: 56px + 12px;
    display: block;
    left: 24px;
    list-style: none;
    margin: 0;
    max-height: calc(100% - 80px);
    overflow-y: auto;
    padding: 0;
    position: absolute;
    right: 24px;
  }

  &-sendMessageForm {
    bottom: 0;
    left: 24px;
    position: absolute;
    right: 24px;
  }

  &-sendMessage {
    display: flex;
  }

  &-input {
    border-right: 0;
  }

  &-sendButton {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0;
  }
}
