@import 'variables'; @import 'variables';
.WrapperWithSidebar {
  flex-wrap: wrap;

  &-sidebar.ant-layout-sider {
    bottom: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 30vw;

    &.ant-layout-sider-below {
      border-top: 2px solid #f0f2f5;
      height: 200px;
      position: fixed;
      top: auto;
    }
  }

  &-mainContent {
    height: 100vh;
    margin-right: 30vw;

    @media only screen and (max-width: 992px) {
      height: calc(100vh - 200px);
      margin-bottom: 200px;
      margin-right: 0;
    }

    &--noSidebar {
      margin-right: 0;

      @media only screen and (max-width: 992px) {
        height: 100vh;
        margin-bottom: 0;
      }
    }
  }
}
