@import 'variables'; @import 'variables';
.Connection {
  margin: 48px 0;
  text-align: center;

  &-gameIdLoadingMessage {
    font-size: 2em;
  }

  &-gameIdLoadingIcon {
    color: #1890ff;
    font-size: 3em;
  }

  &-gameIdMessage {
    font-size: 1.4em;
  }

  &-shareLinkMessage {
    font-size: 1.4em;
    margin-top: 24px;
  }

  &-gameId {
    font-size: 3em;
    position: relative;
  }

  &-joinUrl {
    font-size: 2em;
    position: relative;
  }

  &-copy {
    cursor: pointer;
    font-size: 2rem;
    margin-left: 12px;
    opacity: 0.6;
  }
}
