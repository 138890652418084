@import 'variables'; @import 'variables';
.JoinGame {
  &-content {
    background: white;
    margin: 24px;
    padding: 24px;
  }

  &-connectionLoading {
    padding: 48px 0;
    text-align: center;
  }

  &-connectionLoadingIcon {
    color: #1890ff;
    font-size: 3em;
  }

  &-connectionLoadingMessage {
    font-size: 2em;
  }
}
