@import 'variables'; @import 'variables';
.ChatMessage {
  display: block;
  padding: 0;

  &-sender {
    font-weight: bold;
    margin-top: 6px;
    padding: 0;
  }

  &-message {
    padding: 0;
  }
}
