@import 'variables'; @import 'variables';
.Box {
  height: $boxSize;
  opacity: 0.2;
  width: $boxSize;

  &--conquered {
    opacity: 0.5;
  }
}
