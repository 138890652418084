@import 'variables'; @import 'variables';
.PageHome {
  overflow: auto;

  &-player {
    border-radius: 50px;
    display: inline-block;
    height: 20px;
    width: 20px;
  }

  &-playingFieldContainer {
    display: flex;
    justify-content: center;
    min-width: min-content;
  }
}
