@import 'variables'; @import 'variables';
.Line {
  $lineColor: #888;

  border: 1px solid $lineColor;
  cursor: default;
  display: block;
  padding: 0;

  &--myTurn {
    cursor: pointer;
  }

  &--active {
    cursor: default;
  }

  &--lastMove {
    border: none;
  }

  &--horizontal {
    height: $lineThickness;
    width: $boxSize;
  }

  &--vertical {
    height: $boxSize;
    width: $lineThickness;
  }
}
