@import 'variables'; @import 'variables';
.CreateGame {
  padding: 24px;

  &-content {
    background: white;
    margin-top: 24px;
    padding: 24px;
  }

  &-boardSizeNote {
    line-height: 30px;
    margin-left: 12px;
    opacity: 0.8;
  }
}
